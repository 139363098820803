.home__help-to-buy {
    padding-bottom: 70px;
    border-bottom: 2px solid #DDD;
    .row > div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 992px) {
            display: block;
            text-align: left !important;
            img {
                max-width: 125px;
                margin-bottom: 25px;
            }
        }
    }
    img {
        max-width: 140px;
    }
    @media screen and (max-width: 767px) {
        padding-bottom: 35px;   
        img {
            margin-bottom: 25px;
        }
    }
}

.home__mantle-developments {
    .row > div:nth-child(2) {
        padding-left: 35px;
        @media screen and (max-width: 992px) {
            padding: 25px 15px 0 15px;
        }
    }
}

.home__featured-developments {
    h3 {
        margin-bottom: 30px !important;
    }
    a {
        text-decoration: none;
        &:hover {
            .featured-development .featured-development__image {
                transform: scale(1.1);
                &:before {
                    opacity: 1;
                }
            }
            .button {
                background: $blue;
                color: #FFF;
            }            
        }
    }
    .featured-development {
        position: relative;
        .featured-development__image-container {
            overflow: hidden;
            backface-visibility: hidden;
            transform: rotate(0);
            .featured-development__image {
                position: relative;
                height: 300px;
                background-size: cover;
                background-position: center center;
                transition: transform 1.25s ease;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    background: rgba(255,255,255,0.4);
                    transition: opacity .5s ease;
                }
            }
        }
        .featured-development__icon {
            position: absolute;
            top: 15px;
            right: 15px;
        }
        figcaption {
            margin-top: 20px;
        }
        h4 {
            color: $blue;
        }
        h5 {
            color: $grey;
        }
        .button {
            margin-top: 30px;
        }
        @media screen and (max-width: 1070px) {
            .featured-development__image-container .featured-development__image {
                height: 180px;    
            }
            h4 {
                @include font-size(16);
            }
            h5 {
                @include font-size(11);
            }
        }
    }
    @media screen and (max-width: 767px) {
        .row > div {
            .button {
                margin-bottom: 25px;
            }
            &:last-of-type .button {
                margin-bottom: 0;
            }    
        }
    }
}

@media screen and (max-width: 992px) {
    .home__land-acquisition {
        .row > div {
            &:first-child {
                order: 2;
            }
            &:last-child {
                order: 1;
            }
        }
        img {
            margin-bottom: 25px;
        }   
    }
}

@media screen and (max-width: 767px) {
    .home__mantle-developments img {
        margin-bottom: 25px;
    }      
}