.buying_with_mantle__your-new-mantle-home {
    .nav-tabs {
        max-width: 750px;
        @media screen and (max-width: 992px) { 
            .nav-item {
                flex-basis: 50%;
                border: 2px solid #FFF;
                &:nth-of-type(1) a {
                    border-bottom-left-radius: 0;
                }
                &:nth-of-type(2) a {
                    border-top-right-radius: 21px;
                    border-bottom-right-radius: 0;
                }
                &:nth-of-type(3) a {
                    border-bottom-left-radius: 21px;
                }
                &:nth-of-type(4) a {
                    border-top-right-radius: 0;
                }
            }    
        }
    }
    .tab-content img {
        margin-bottom: 25px;
    }    
}

.buying_with_mantle__warranties {
    .panel__cols {
        h4 {
            margin-bottom: 5px;
            @include font-size(17);
        }
        p {
            @include font-size(11);
        }
    }
}