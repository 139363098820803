@import url("https://use.typekit.net/ppk8mwf.css");


h1, h2, h3, h4, h5, h6 {
    font-family: goudy-old-style, serif;
    font-weight: 700;
    margin: 0;
    color: $blue;
}

h1 {
    @include font-size(44);
}

h2 {
    @include font-size(25);
}

h3 {
    @include font-size(22);
}

h4 {
    @include font-size(18);
}

h5 {
    @include font-size(14);
}

h6 {
    @include font-size(12);
}

p, li {
    font-family: din-2014, sans-serif;
    font-weight: 300;
    margin-bottom: 1em;
    color: $grey;
    @include font-size(12);    
}

p:last-of-type {
    margin-bottom: 0;
}