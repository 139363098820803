.land_acquisition__intro {
    h4 {
        margin: 1.5em 0 .5em;
    }
    form {
        max-width: 800px;
        margin: 50px auto 0;
        input:not([type=submit]), select, textarea {
            width: 100%;
            padding: 10px 15px;
            margin-bottom: 15px;
            border-radius: 5px;
            border: 1px solid lighten($grey, 20%);
            color: $grey;
            &:focus {
                outline: none;
                border: 1px solid $blue;
            }
        }
        textarea {
            resize: none;
        }
        .button {
            margin-top: 0;
            width: 100%;
            border-color: $blue;
            background: $blue;
            color: #FFF;
        }
    }
}

.land_acquisition__case-studies--carousel {
    padding: 50px;
    min-height: 345px;
    background: #FFF;
    text-align: left;
    h4 {
        margin-bottom: .5em;
    }
    p {
        @include font-size(10);
    }
    .carousel-inner {
        min-height: 245px;
    }
    .carousel-control-prev {
        left: -150px;
        .carousel-control-prev-icon {            
            transform: rotate(90deg);
            width: 30px;
            height: 30px;
            background-image: url(/images/down.svg);
            background-size: 30px 30px;
        }
    }
    .carousel-control-next {
        right: -150px;
        .carousel-control-next-icon {            
            transform: rotate(-90deg);
            width: 30px;
            height: 30px;
            background-image: url(/images/down.svg);
            background-size: 30px 30px;
        }
    }
    @media screen and (max-width: 600px) {
        padding: 25px;
        min-height: 730px;
        .carousel-inner {
            min-height: 625px;
        }
        img {
            margin-bottom: 25px;
        }
        .carousel-control-prev, .carousel-control-next {
            display: none;    
        }
    }
}