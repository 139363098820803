.our_developments__form {
    label {
        display: block;
        font-weight: bold;
        @media screen and (max-width: 992px) {
            margin-top: 10px;    
        }
    }
    input, select, .dropdown button {
        display: block;
        width: 100%;
        height: 39px;
        border: none;
        border-radius: 8px;
        padding: 8px 15px;
        color: $brown;
        @include font-size(9);
        &:focus {
            outline: none;
        }
    }
    input[type=submit] {
        margin-top: 32px;
        height: 39px;
        border-radius: 23px;
        background: $brown;
        font-weight: bold;
        color: #FFF;
        @include font-size(10);
    }
}

.our_developments__links {    
    a {
        margin: 0 20px;
        text-decoration: none;
        transition: .2s ease;
        svg {
            margin-left: 5px;
            transition: transform .2s ease;
            circle {
                fill: #ACA199;
                stroke: none;
                transition: fill .2s ease;
            }
        }
        &:hover {
            color: $blue !important;
            svg {
                transform: scale(1.1);
                circle {
                    fill: $blue;
                }
            }
        }
        @media screen and (max-width: 600px) {
            display: block;
            padding: 7px 0;
        }
    }
    @media screen and (max-width: 767px) {
        text-align: center !important;
    }
}

.our_developments__request-callback, .our_developments__join-mailing-list {
    display: none;
    form {        
        margin-top: 25px;
        .flex-surround {
            display: flex;
            justify-content: space-between;
        }
        input {
            width: 100%;
            max-width: 100%;
            height: 39px;
            border: none;
            border-radius: 8px;
            padding: 8px 15px;
            color: $brown;
            @include font-size(9);
            &:focus {
                outline: none;
            }
            &[type=submit] {
                border-radius: 23px;
                background: $brown;
                font-weight: bold;
                color: #FFF;
                @include font-size(10);
            }
        }
        .wpcf7-form-control-wrap {
            flex-basis: 24%;
        }        
        .dropdown {
            flex-basis: 28%;
            button {
                height: 39px;
                color: $brown;
                @include font-size(9);
            }
        }  
        input[type=submit] {
            flex-basis: 17%;
        }
        .ajax-loader {
            display: none !important;
        }
        .wpcf7-not-valid-tip {
            margin-top: 5px;
            color: #C00;
            @include font-size(9);
        }
        @media screen and (max-width: 850px) {
            .flex-surround {
                flex-wrap: wrap;
            }
            .wpcf7-form-control-wrap, .dropdown, input[type=submit] {
                flex-basis: 48%;    
            }
            input, .dropdown {
                margin-bottom: 15px;
            }
        }
        @media screen and (max-width: 767px) {
            .wpcf7-form-control-wrap, .dropdown, input[type=submit] {
                flex-basis: 100%;    
            } 
        }
    }  
}

.our_developments__controls {
    margin-bottom: 25px;
    padding-bottom: 50px;
    border-bottom: 1px solid $blue;
    .nav-tabs {
        margin: 0;
        text-align: center;
        flex-wrap: nowrap;
        .nav-item {
            flex-basis: 25%;
        }
        .nav-link:not(.active) {
            transition: background .2s ease;
            &:hover {
                background: darken(#DCF0F1, 5%);
            }
        }        
        @media screen and (max-width: 767px) {
            max-width: 100%;
            .nav-link {
                padding: 8px 5px;
            }
        }
    }
    .our_developments__controls--views {
        width: 180px; 
        float: right;
        display: flex;
        justify-content: space-between;
        a {            
            &:hover {
                svg .blue {
                    fill: darken(#DCF0F1, 10%);
                }
            }
            &.active svg .blue {
                fill: $blue;
            }
        }
        svg {
            height: 42px;
            max-width: 65px;
            * {
                transition: fill .2s ease;
                stroke: #FFF;
                fill: #FFF;
            }
            .blue {
                fill: #DCF0F1;
            }
        }
        @media screen and (max-width: 767px) {
            text-align: center !important;
            padding-top: 15px;
        }
    }
    @media screen and (max-width: 767px) {
        padding-bottom: 25px;    
    }
}

.our_developments__results {
    article {   
        border-bottom: 1px solid #DDD;
        &:last-of-type {
            border-bottom: none;
        }
        a {
            display: block;
            position: relative;
            padding: 25px 100px 25px 40px;
            text-decoration: none !important;
            transition: background .2s ease;
            @media screen and (min-width: 768px) {
                &:hover {
                    background-color: lighten($blue, 20%); 
                    * {
                        color: #FFF;
                    }
                    svg path {
                        fill: #FFF;
                    }
                    .result__image {
                        transform: scale(1.1);
                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
            @media screen and (max-width: 767px) {
                padding: 25px 60px 25px 0;
            }
        }
        figure {
            position: relative;
            height: 120px;
            margin-bottom: 27px;
            background-color: #EEE;
            .result__image-container {
                height: 100%;
                overflow: hidden;
            }
            .result__image {
                position: relative;
                height: 100%;
                background-size: cover;
                background-position: center center;
                transition: transform 1.25s ease;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    background: rgba(255,255,255,0.4);
                    transition: opacity .5s ease;
                }
            }
            figcaption {
                position: absolute;
                bottom: -27px;
                display: block;
                width: 100%;                
                padding: 3px 5px;
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
                color: #FFF;
                @include font-size(9);
                &.future {
                    background: #FCD24D;
                }
                &.current {
                    background: #DE9F93;
                }
                &.past {
                    background: #BCBEC0;
                }
            }
            img {
                position: absolute;
                top: -10px;
                left: -10px;
                width: 35px;
            }
            @media screen and (max-width: 575px) {
                display: none;    
            }
        }
        h3 {
            margin: 20px 0 5px;
            @media screen and (max-width: 767px) {
                margin-top: 0;    
            }
            @media screen and (max-width: 600px) {
                @include font-size(18);
            }
        }
        h4 {
            margin-bottom: 10px;
            color: $brown;
            @include font-size(14);
            @media screen and (max-width: 600px) {
                @include font-size(12);
            }
        }
        a > svg {
            position: absolute;
            width: 50px;
            height: 50px;
            top: 70px;
            right: 40px;
            @media screen and (max-width: 767px) {
                right: 0;    
            }
            @media screen and (max-width: 575px) {
                width: 40px;
                top: 55px;    
            }
        }
        @media screen and (max-width: 600px) {
            p {
                @include font-size(10);
            }
        }
        &.grid {
            border-bottom: 2px solid $blue;
            padding-bottom: 15px;
            margin: 25px 0;
            figure {
                height: 200px;
                .result__image-container {
                    height: 200px;
                }
                .result__image {
                    height: 200px;
                }
                img {
                    left: auto;
                    top: 10px;
                    right: 10px;
                }
                @media screen and (max-width: 575px) {
                    display: block;    
                }
            }
            a {
                padding: 0;
                text-align: center;
                h3 {
                    margin: 42px 0 0;
                    @include font-size(16);
                }
                h4 {
                    margin: 0;
                    @include font-size(12);
                }
                &:hover {
                    background: none;
                    p {
                        color: $grey;
                    }
                    h3 {
                        color: $blue;
                    }
                    h4 {
                        color: $brown;
                    }                    
                }
            }
        }
    }
}



/* Individual developments*/
.development__content {
    h1 {
        @include font-size(36);
        @media screen and (max-width: 600px) {
            @include font-size(25);    
        }
    } 
    h3 {
        color: $light_brown;
        @include font-size(16);
        @media screen and (max-width: 600px) {
            @include font-size(14);    
        }
    }
    .separator {
        position: relative;
        margin: 35px auto;
        &, &:before, &:after {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #DDD;
        }
        &:before, &:after {
            position: absolute;
            content: "";
            display: block;
            top: 0;
            left: -20px;
        }
        &:after {
            left: auto;
            right: -20px;
        }
        @media screen and (max-width: 600px) {
            margin: 35px 25px;
        }
    }
    .development__content--links {
        margin: 50px 0;
        border-bottom: 1px solid #DDD;
        ul {
            display: flex;
            justify-content: space-between;            
            padding-bottom: 50px;            
            text-align: center;
        }
        li {
            margin: 0;  
            a {
                @include font-size(10);
                color: $brown !important;
                font-weight: 400;
                transition: color .2s ease;
                &:hover {
                    color: $blue !important;
                    text-decoration: none;
                    svg {
                        transform: scale(1.1);
                        * {
                            fill: #FFF;
                        }
                        circle {
                            fill: $blue;
                        }
                    }
                }
            }
        }        
        svg {
            display: block;
            margin: 0 auto 10px;
            transition: transform .2s ease;
            * {
                transition: fill .2s ease;
            }
        }
        .our_developments__request-callback, .our_developments__join-mailing-list {
            h3 {
                margin: 0;
            }
            p {
                 @include font-size(10);
            }
        }
        form {
            padding-bottom: 50px;
            input[type=text], input[type=tel], input[type=email], .dropdown button {
                background: lighten($tan, 12%);
            }
        }
        @media screen and (max-width: 767px) {
            flex-wrap: wrap;
            justify-content: flex-start;
            li {
                flex-basis: 33%;   
                margin: 5px 0;
            }
        }
        @media screen and (max-width: 600px) {
            ul {
                justify-content: space-around;
                flex-wrap: wrap;
                li {
                    flex-basis: 33%;
                    line-height: 110%;
                }
            }
        }
        
    }
    .development__content--agent {
        padding-bottom: 50px;
        border-bottom: 1px solid #DDD;
        .row > div {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            & > * {
                flex-basis: 100%;
            }
            @media screen and (max-width: 600px) {
                justify-content: flex-start;    
            }
        }
        h3 {
            margin-bottom: 0;
            @include font-size(20);
            span {
                color: $brown;
            }
            @media screen and (max-width: 767px) {
                margin-bottom: 25px;    
            }
            @media screen and (max-width: 600px) {
                @include font-size(18);
            }
        }
        p {
            @include font-size(11);
            margin: 0;
        }
        img {
            max-width: 180px;
            margin: 15px 0;
        }
    }
    #development__content--tabs {
        position: relative;
        z-index: 500;
        margin: 125px auto 0;
        max-width: 830px;
        li {
            flex-basis: 25%;
        }
        @media screen and (max-width: 810px) {
            .nav-item {
                &:first-child, &:nth-child(2) {
                    .nav-link {
                        line-height: 40px;
                    }
                }
            }
        }
        @media screen and (max-width: 600px) {
            margin-top: 50px !important;
            li {
                border: 2px solid #FFF;
                flex-basis: 50%;
                a {
                    padding: 12px 5px;
                }
                &:nth-of-type(1) a {
                    border-bottom-left-radius: 0;
                }
                &:nth-of-type(2) a {
                    border-top-right-radius: 21px;
                    border-bottom-right-radius: 0;
                }
                &:nth-of-type(3) a {
                    border-bottom-left-radius: 21px;
                }
                &:nth-of-type(4) a {
                    border-top-right-radius: 0;
                }
            }
        }
        @media screen and (max-width: 400px) {
            margin-top: 150px;
        }
    }
    .tab-content {
        .tab-pane .panel__cols div {
            display: inline-block;
        }
        h2 {
            margin-top: -125px;
            padding-bottom: 105px;
            text-align: center;
            @media screen and (max-width: 600px) {
                margin-top: 25px;    
                padding-bottom: 0;
            }
        }
        h4 {
            margin-bottom: 10px;
            @include font-size(16);
        }
        p {
            margin-bottom: 15px;
            @include font-size(11);
        }
        ul { 
            margin-bottom: 25px;
            li {
                margin: 0 0 3px 1em;
                @include font-size(11);
                &:before {
                    content: "\2022";
                    display: inline-block;
                    color: $light_brown;
                    font-weight: bold;
                    width: 1em;
                    margin-left: -1em;
                }
            }
        }
        table {
            margin-bottom: 25px;
            th, td {
                padding: 8px 15px;
            }
            th {
                font-family: goudy-old-style, serif;
                background: $brown;
                color: #FFF;
            }
            img {
                height: 20px;
                margin-bottom: 3px;
            }
            tr {
                border-bottom: 1px solid #DDD;
                &:hover, &.active {
                    td {
                        background: lighten($blue, 20%);
                        cursor: pointer;
                        color: #FFF;
                    }
                }
                &.active td {
                    background: $blue;
                }
            }
            @media screen and (max-width: 600px) {
                th, td {
                    padding: 5px 8px;
                    @include font-size(10);
                }    
            }
        }
        @media screen and (max-width: 600px) {
            #properties.tab-pane {
                overflow: scroll;
            }    
        }
        .development__content--property_details {
            display: none;
            table { 
                th {
                    background: $light_brown;
                }
                tr:hover td {
                    background: #FFF;
                    color: #000;
                    cursor: inherit;
                }
            }
            a {
                display: block;
                text-align: center;
                color: $grey;
                img {
                    display: block;
                    margin: 0 auto 20px;
                    max-height: 180px;
                }
            }
        }
    }
    .development__content--gallery {
        margin-top: 50px;
        figure {
            height: 10vw;
            max-height: 130px;
            margin-bottom: 30px;
            background-size: cover;
            background-position: center center;
        }
        .col-md-3 figure {
            height: 16vw;
            max-height: 210px;
        }
        .col-md-4 figure {
            height: 22vw;
            max-height: 290px;
        }
        @media screen and (max-width: 600px) {
            figure {
                height: 25vw;
                margin-left: 0;
                margin-right: 0;
            }    
        }
    }
}
.development__content--map {
    position: relative;
    height: 480px;
    background: url(/images/blurred_map.jpg) #EEE;
    background-position: center center;
    background-size: cover;
    .button {
        position: absolute;
        left: 50%;
        top: 50%;
        margin: 0;
        transform: translate(-50%, -50%);
        font-weight: bold;
    }
}
