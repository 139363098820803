body {
    max-width: 1800px;
    padding-top: 155px;
    margin: 0 auto;
    overflow-x: hidden;
    background: #FFF;
    &.customize-support header {
        top: 32px;
        nav {
            @media screen and (max-width: 1070px) {
                height: calc(100vh - 152px);
                top: 152px;
            }
            @media screen and (max-width: 600px) {
                height: calc(100vh - 146px) !important;
                top: 146px;    
            }
        }
        @media screen and (max-width: 768px) {
            top: 46px; 
        }
    }
    &.sticky header {
        padding: 25px 0;
        height: 120px;
        background: rgba(255, 255, 255, 0.9);
        img {
            width: 150px;
        }
        @media screen and (max-width: 1070px) {            
            height: 120px;    
        }        
    }
    @media screen and (max-width: 1070px) {
        padding-top: 120px;    
    }
    @media screen and (max-width: 600px) {
        padding-top: 100px;    
    }
}

img {
    max-width: 100%;
}

.wrapper {
    max-width: 1230px;
    padding: 0 35px;
    margin: 0 auto;
    &.narrow {
        max-width: 1000px;
    }
    @media screen and (max-width: 600px) {
        padding: 0 25px;    
    }
}

header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 155px;
    padding: 40px 0;
    background: #FFF;
    transition: .25s ease;
    img {
        transition: .25s ease;
    }
    nav {
        float: right;
        width: 800px;
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 75px;
            li {
                margin: 0;
                a {
                    @include font-size(11);
                    font-weight: 400;
                    transition: all .2s ease;
                    color: $light_brown;
                    &:hover {
                        color: $brown;
                        text-decoration: none;
                    }
                    &.active {
                        color: $blue;
                    }
                    &.button {
                        display: block;
                        margin-top: 0;
                        padding: 10px 25px;
                        border-radius: 23px;
                        border: none;
                        color: #FFF !important;
                        &:hover {
                            color: #FFF;
                        }
                        &.button--dark {
                            background: $brown !important;
                            &:hover {
                                background: lighten($brown, 8%);
                            }
                        }
                        &.button--light {
                            background: $light_brown !important;
                            &:hover {
                                background: lighten($light_brown, 8%);
                            }
                        }
                    }
                }
            }
        }        
    }
    .header__burger {
        display: none;
        position: absolute;
        width: 42px;
        height: 40px;
        top: 42px;
        right: 35px;
        transition: .5s ease-in-out;
        cursor: pointer;
        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 34px;
            background: $brown;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;        
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2), &:nth-child(3) {
                top: 13px;
            }
            &:nth-child(4) {
                top: 26px;
            }        
        }
        &.open {
            span {
                &:nth-child(1) {
                  top: 18px;
                  width: 0%;
                  left: 50%;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                    width: 100%;
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                    width: 100%;
                }
                &:nth-child(4) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }
    @media screen and (max-width: 1070px) {
        height: 120px;
        padding: 25px 0;
        img {
            height: 70px;
        }
        nav {
            display: none;
            position: fixed;
            top: 120px;
            height: calc(100vh - 120px);
            left: 0;
            width: 100%;
            float: none;
            padding: 0;
            background: rgba(255,255,255,.9);
            ul {
                display: flex;
                width: 100%;
                height: 100%;
                flex-direction: column;
                justify-content: flex-start;
                li {                    
                    flex-basis: 17%;
                    border-top: 1px solid #EEE;
                    display: block;
                    width: 100%;
                    a {
                        display: flex !important;
                        flex-direction: column;
                        align-content: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        padding: 0 !important;
                        text-align: center;
                        background: #FFF;
                        color: $brown !important;
                        border-radius: 0 !important;
                        @include font-size(18);
                        
                    }
                }
            }
        }
        .header__burger {
            display: block;
        }
    }
    @media screen and (max-width: 600px) {
        height: 100px !important;
        padding: 15px 0 !important;   
        .header__logo {
            width: 120px !important;
        }   
        .header__burger {
            top: 35px;
            right: 25px;
        }
        nav {
            top: 100px;
            height: calc(100vh - 100px) !important;
            ul li a {
                @include font-size(14);
            }
        }
    }
}

.carousel {
    position: relative;
    .carousel__slide {
        .carousel__content {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translateY(calc(-50% - 35px));
            padding: 0 15px;            
            h1, p, a {
                color: #FFF;
            }
            h1 {
                margin-bottom: 30px;
            }
            p {
                @include font-size(12);
                max-width: 600px;  
                margin-bottom: 40px;
            }
            a {
                display: inline-block;
                padding: 10px 25px 12px;
                border-radius: 27px;
                border: 2px solid #FFF;
                font-weight: 500;
                color: #FFF;
                transition: .2s ease;
                @include font-size(12);
                &:hover {
                    text-decoration: none;
                    background: #FFF;
                    color: $brown;
                }
            }
            @media screen and (max-width: 992px) {            
                transform: translateY(-50%);
                h1 {
                    @include font-size(38);
                    margin-bottom: 20px;
                }
                p {
                    @include font-size(12);
                    max-width: none;
                    margin-right: 30px;
                    margin-bottom: 30px;
                }
            }
        }
        
    }
    .carousel-indicators {
        display: block;
        left: auto;
        right: 75px;
        bottom: 50%;
        transform: translateY(50%);
        counter-reset: indicators;
        margin: 0;
        li {
            width: 4px;
            height: 40px; 
            margin: 0 0 20px;
            text-indent: 0;
            &::before, &::after {
                height: 40px;
                top: 0;
                bottom: 0;
            }
            &::after {
                display: block;
                counter-increment: indicators;
                content: "0" counter(indicators); 
                width: 20px;
                top: 5px;
                right: -30px;
                left: auto;
                color: #FFF;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            &.active {
                background-color: $blue;
                width: 10px;
            }
        }
        @media screen and (max-width: 992px) {
            display: none;    
        }
    }
    .carousel__slide--curve {
        position: absolute;
        left: 0;
        bottom: -1px; /* Weird IE fix */
        width: 100%;
        @media screen and (max-width: 992px) {
            display: none;    
        }
    }    
    & + main .panel:first-of-type {
        padding-top: 0;
    }
    @media screen and (max-width: 992px) {
        margin-bottom: 35px;    
    }
    @media screen and (max-width: 767px) {
        .carousel__content, .carousel-indicators {
            display: none;    
        }
    }
}

main {    
    .panel {
        padding: 90px 0;
        section {
            margin-bottom: 90px;
            a:not(.button) {
                color: $blue;
            }
            h2, h3 {
                margin-bottom: 20px;            
            }
            h4 {
                color: $light_brown;
            }
            p.intro {
                font-weight: 700;
            }
            p.brown {
                color: $light_brown;
            }
            .panel__cols {
                column-count: 2;
                column-gap: 2em;
                column-break-inside: avoid;
                @media screen and (max-width: 992px) {
                    column-count: 1;
                }
            }
            .content-image {
                margin: 50px 0;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        &.grey {
            background: $light_grey;
        }
        &.alt-grey {
            background: $alt-grey;
            h3 {
                color: $brown;
            }
        }
        &.brown {
            background: $brown;
            h2 {
                color: $light_brown;
            }
            p, span, li, a, label {
                color: #FFF;
            }
        }
        &.tan {
            background: $tan;
            h2 {
                color: $brown;
            }
            p, span, li, a, label {
                color: #FFF;
            }
        }
        &.narrow {
            padding: 10px 0;
        }
        &.mid {
            padding: 50px 0;
        }
        &.image {
            padding: 0;
        }
        &.centered {
            text-align: center;
            p.intro {
                margin-bottom: 1.5em;    
            }
            form {
                text-align: left;
            }
            .panel__cols {
                p, h4 {
                    text-align: left;
                }
            }
            @media screen and (max-width: 767px) {
                text-align: left !important;    
            }
        }        
        @media screen and (max-width: 767px) {
            padding: 35px 0;
            section {
                margin-bottom: 35px;
            }
            .button {
                width: 100%;
                text-align: center;
            }
        }
    }
}

.nav-pills, .nav-tabs {
    margin: 30px auto 45px; 
    max-width: 580px;
    border-bottom: none;
    justify-content: space-between;
    .nav-item {
        flex-grow: 1;
        flex-basis: 0;
        &:first-of-type .nav-link {
            border-top-left-radius: 21px;
            border-bottom-left-radius: 21px;
        }
        &:last-of-type .nav-link {
            border-top-right-radius: 21px;
            border-bottom-right-radius: 21px;
        }
    }
    .nav-link {
        border: none;
        border-radius: 0;
        background: $light_blue;
        font-weight: 400;
        color: $blue;      
        text-align: center !important;
        @include font-size(11);
        &.active {
            background: $blue;
            border: none;
            color: #FFF;
        }
        @media screen and (max-width: 992px) {
            height: 100%;
            line-height: 120%;
        }
    }
    
}

footer {
    .footer__top, .footer__mid, .footer__bottom {
        padding: 15px 0;
    }
    .footer__top {
        padding: 25px 0;
        background: $light_brown;
        h6 {
            margin-bottom: 15px;
            font-family: din-2014, sans-serif;
            font-weight: 500;
            letter-spacing: 1px;
            color: #FFF;            
            @include font-size(11);
        }
        p, li, a {
            @include font-size(9);
            color: #FFF;
            margin-bottom: 0;
        }
        & > .wrapper > .row > div {
            position: relative;
            &:after {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                right: 15px;
                bottom: 0;
                border-right: 1px solid #FFF;
            }
            &:last-of-type:after {
                display: none;
            }
        }
        
    }
    .footer__mid {
        background: $brown;
        text-align: right;
        &, a {
            color: #FFF;
            @include font-size(9);
        }
    }
    .footer__bottom {        
        p {
            @include font-size(9);
        }
    }
    @media screen and (max-width: 992px) {        
        .footer__top > .wrapper > .row > div {
            &:nth-of-type(2n):after {
                display: none;
            }
            &:nth-of-type(3), &:nth-of-type(4) {
                h6 {
                    margin-top: 25px;
                }
            }
        }
        .footer__mid {
            text-align: center;
        }
        .footer__top {
            padding-bottom: 5px; 
            h6 {
                position: relative;
                margin: 0 0 20px !important;
                padding: 0 25px 5px 0;
                border-bottom: 1px solid #CCC;
                cursor: pointer;
                &:after {
                    position: absolute;
                    content: "";
                    right: 0;
                    top: 0;
                    display: block;
                    background-image: url(/images/down.svg);
                    background-size: 20px 20px;
                    width: 20px;
                    height: 20px;
                    transition: .4s linear;
                }
                & + .row-wrapper, & + ul {
                    display: none;
                }
                & + .row-wrapper {
                    padding-bottom: 20px;
                }
                &.open:after {
                    transform: rotate(180deg);
                }
            }
            ul {
                margin-bottom: 30px;
            }
            p, li, a {
                @include font-size(10);                
            }
            li a {
                display: block;
                padding: 7px 0;
            }
            & > .wrapper > .row > div:after {
                display: none;    
            }
        }
        .footer__bottom {
            text-align: center;
        }
    }
}

.button {
    display: inline-block;
    padding: 10px 25px;
    margin-top: 35px;
    border-radius: 27px;
    border: 2px solid $blue;
    font-weight: 400;
    color: $brown;
    transition: all .2s ease;
    &:hover {
        text-decoration: none;
        background: $blue;
        color: #FFF;
    }
    &.brown {
        background: $brown;
        border: none;
        color: #FFF;
        &:hover {
            background: $light_brown;
        }
    }
}

.dropdown {
    button {
        position: relative;
        width: 100%;
        height: 43px;
        padding: 8px 15px;
        background: #FFF;
        border: none;
        border-radius: 8px;
        text-align: left;
        color: $brown;
        &:focus {
            outline: none;
        }
        &:after {
            position: absolute;
            right: 15px;
            top: 11px;
            display: block;
            border: none;
            background-image: url(/images/down.svg);
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
        }
    }
    .dropdown-menu {
        width: 100%;
        max-height: 250px;
        margin-top: 0;
        padding: 0;        
        border-radius: 0;        
        overflow: auto;
        overflow-x: hidden;
        a {
            padding: 10px 15px;
            color: $brown !important;
            @include font-size(9);
        }
    }
}

@media screen and (max-width: 790px) {
    #wpadminbar {
        position: fixed !important;
    }    
}

.text_only__content {
    h1 {
        text-align: center;
        margin-bottom: 20px;
        @media screen and (max-width: 600px) {
            text-align: left;
            @include font-size(30);
        }
    }
    h2 {
        margin-top: 35px;
        @media screen and (max-width: 600px) {
            @include font-size(20);
        }
    }
    ul {
        margin: 0 0 0 50px;
        li {
            list-style-type: disc;
        }
    }
}

.anchor-link {
    display: block;
    margin-top: -120px;
    padding-top: 120px;
    pointer-events: none;
}

.parallax-window {
    min-height: 400px;
    background: transparent;
}

.grecaptcha-badge { visibility: hidden; }