.about_us__who-we-are {
    figure.team-member {
        margin-top: 35px;
        figcaption {
            border-top: 5px solid $blue;
            border-bottom: 2px solid $blue;
            background: #FFF;
            padding: 25px 35px;
            min-height: 265px;
            h4 {
                color: $blue;
            }
            h5 {
                color: $grey;
                margin-bottom: 20px;
            }
            p {
                @include font-size(9);
            }
            @media screen and (max-width: 600px) {
                min-height: inherit;    
            }
        }
        @media screen and (max-width: 992px) {
            position: relative;
            padding-left: 220px;
            figcaption {
                min-height: inherit;
                height: 220px;
                border-bottom-width: 5px;
                text-align: left;
            }
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 220px;
            }
        }
        @media screen and (max-width: 600px) {
            position: relative;
            padding-left: 0;
            figcaption {
                height: auto;
                padding: 25px;
            }
            img {
                position: static;
                width: 100%;
            }
        }
    }    
}