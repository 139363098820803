.contact_us__form {
    form {
        max-width: 800px;
        margin: 0 auto;
        p {
            display: none;
        }
        input:not([type=submit]), select, textarea, .dropdown button {
            width: 100%;
            padding: 10px 15px;
            margin-bottom: 15px;
            border-radius: 5px;
            border: 1px solid #E0D9D5;
            background: #E0D9D5;
            color: $brown;
            transition: .2s ease;
            @include font-size(9);
            &:focus {
                outline: none;
                border: 1px solid $blue;
                background: #FFF;
            }
            &.wpcf7-not-valid {
                border: 1px solid #C00;
                background: lighten(#C00, 57%);
            }
        }
        textarea {
            resize: none;
        }
        .dropdown {
            button {
                background: #E0D9D5;                
            }
            .dropdown-menu {                
                border: 1px solid $blue;  
                border-top: none;
                color: $brown;                
                a {                    
                    color: $brown;
                }
            }
        }
        .button {
            margin-top: 0;
            width: 100%;
            border-color: $light_brown;
            background: darken($brown, 5%);
            font-weight: bold;
            color: #FFF;
            &:hover {
                background: $light_brown;
            }
            &:focus {
                outline: none;
            }
        }
        .address, .contacts {
            @include font-size(10);
            a {
                color: #FFF !important;
            }
        }
        .wpcf7-not-valid-tip {
            margin: -12px 0 15px;
            color: #900 !important;
            @include font-size(9);
        }
        .wpcf7-validation-errors, .wpcf7-acceptance-missing {
            background: #FFF;
            margin: 0;
            padding: 25px;
            border: 1px solid #C00;
            font-weight: bold;
        }
    }
}